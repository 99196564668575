import * as React from "react";

import { PERIOD } from "~/constants/dates";
import {
  RANGE_GETTERS,
  SCALE,
  SESSION,
  UMBRELLA_BILLING_MODE
} from "~/constants/filters";
import { Period, Range } from "~/declarations/filters";

export interface DashboardLayoutContextProps {
  filter: {
    fromDt?: string;
    tillDt?: string;
    scale: SCALE;
    lesson: SESSION;
    subjects?: number[];
  };
  selectedPeriod?: Period;
  umbrellaLimits: {
    billingMode: UMBRELLA_BILLING_MODE;
    minutes: number;
    expires: string;
  };
  restrictedScales?: SCALE[];
  explicit: boolean;
}

const initRange: Range = RANGE_GETTERS[PERIOD.last30Days]();

export default React.createContext<DashboardLayoutContextProps>({
  filter: {
    fromDt: initRange[0]?.toISOString(),
    tillDt: initRange[1]?.toISOString(),
    scale: SCALE.daily,
    lesson: SESSION.all
  },
  umbrellaLimits: {
    billingMode: 0,
    minutes: 0,
    expires: ""
  },
  explicit: false
});
