import { Listbox, Transition } from "@headlessui/react";
import ArrowIcon from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import classNames from "classnames";
import React from "react";
import { useId } from "react-aria";

import { menuTransitionClasses } from "~/components/layouts/AccountLayout/AccountHeader/AccountUser/UserMenu";

import ClarificationIcon from "../ClarificationIcon";
import { IInputCommonProps, ISelectOption } from "../FormsCommon";

export interface ISelectProps extends IInputCommonProps {
  options: ISelectOption[];
  selectedOption?: ISelectOption;
  onChange: (value: string) => void;
  clarification?: string | JSX.Element;
  optionsClassname?: string;
  testidButton?: React.ButtonTestID;
  testidOption?: (id: number | string) => React.ButtonTestID;
}

const Select: React.FC<ISelectProps> = ({
  disabled,
  required,
  className,
  label,
  description,
  options,
  selectedOption,
  onChange,
  clarification,
  optionsClassname,
  testidButton,
  testidOption
}) => {
  const buttonId = useId();
  const descId = useId();

  return (
    <Listbox
      as="div"
      disabled={disabled}
      className={classNames(className, "relative")}
      onChange={onChange}
    >
      {label && (
        <Listbox.Label
          htmlFor={buttonId}
          className={classNames(
            "inputLabel flex items-center justify-between w-full gap-[4px]",
            disabled && "text-gray-600"
          )}
        >
          {label}
          {clarification && (
            <ClarificationIcon clarification={clarification} small />
          )}
        </Listbox.Label>
      )}
      {description && (
        <div
          className={classNames(
            "explanatoryText mt-[-4px] mb-[8px]",
            disabled && "text-gray-600"
          )}
          id={descId}
        >
          {description}
        </div>
      )}
      <Listbox.Button
        as="div"
        role="combobox"
        tabIndex={!disabled ? 0 : undefined}
        id={buttonId}
        aria-describedby={
          description ? `${description ? descId : ""}` : undefined
        }
        aria-required={required}
        className={classNames(
          "min-h-[40px] formInput formInputBorderStyle block mt-[8px] relative items-center pr-[40px] whitespace-nowrap overflow-hidden text-ellipsis"
        )}
        data-testid={testidButton}
      >
        <ArrowIcon
          aria-hidden="true"
          className="w-[24px] h-[24px] absolute right-[8px] pointer-events-none transition duration-200 ui-open:rotate-180"
        />
        {selectedOption && selectedOption.label}
      </Listbox.Button>

      <Transition as={React.Fragment} {...menuTransitionClasses}>
        <Listbox.Options
          className={classNames(
            "absolute w-[calc(100%+2px)] ml-[-1px] z-10 mt-[4px] max-h-72",
            "bg-white rounded-[4px] overflow-auto outline-none shadow-default",
            optionsClassname
          )}
        >
          {options.map(option => (
            <Listbox.Option
              key={option.value}
              value={option.value}
              className={classNames(
                "selectDropDownOptionStyle w-full px-4 py-2 outline-none cursor-pointer paragraph select-none",
                option.value === selectedOption?.value && "bg-gray-200"
              )}
              data-testid={testidOption?.(option.value)}
            >
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

export default Select;
